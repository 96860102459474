import React from 'react'
import WareHousingScreen from './WareHousingScreen'

export default class LogisticsScreenComponent extends React.Component {
    render() {
        return(
            <section style={{marginTop: 100}}>
                <WareHousingScreen />
            </section>
        )
    }
}