import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import ContactScreen from './views/ContactScreen';
import TeamsScreen from './views/TeamScreen'
import TrademarkScreen from './views/TrademarkScreen';
import FBAScreen from './views/FBAScreen';
import AccountingScreen from './views/AccountingScreen';
import LogisticsScreen from './views/LogisticsScreen';
import PrivacyScreen from './views/PrivacyScreen';
import BookScreen from './views/BookScreen';
import PricingScreen from './views/PricingScreen';
import FAQScreen from './views/FAQScreen';


// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute path="/contact" component={ContactScreen} />
          <AppRoute path="/about-us" component={TeamsScreen} />
          <AppRoute path="/fba" component={FBAScreen} />
          <AppRoute path="/accounting" component={AccountingScreen} />
          <AppRoute path="/logistics" component={LogisticsScreen} />
          <AppRoute path="/trade" component={TrademarkScreen} />
          <AppRoute path="/privacy" component={PrivacyScreen} />
          <AppRoute path="/book" component={BookScreen} />
          <AppRoute path="/prices" component={PricingScreen} />
          <AppRoute path="/faq" component={FAQScreen} />
        </Switch>
      )} />
  );
}

export default App;