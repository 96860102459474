import React, { useEffect, useState } from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesTiles2 from '../components/sections/FeaturesTiles2';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Home = () => {
  const [hideCookie, setHideCookie] = useState(localStorage.getItem('cookie_hide') !== null)
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    window.scrollTo(0,0)
    console.log("cookie", hideCookie)
  }, [])

  const hideCookieMenu = () => {
    setHideCookie(true)
    localStorage.setItem('cookie_hide', 'hidden')
  }

  return (
    <>
      <Hero className="illustration-section-01" />
      <div style={{marginTop: '-15%'}}>
        <FeaturesTiles />
      </div>
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      {/* <FeaturesTiles2 /> */}
      <Testimonial topDivider />
      

      {
        hideCookie ? null : 
        <div style={{position: 'fixed', bottom: 0, backgroundColor: '#515151', width: '100%'}}>
          <p style={{paddingTop: 5, paddingBottom: 5, color: '#f3f3f3', marginLeft: 5, marginTop: 5, fontWeight: '500', fontSize: '17px'}}>This website uses cookie to ensure you receive your best experience. <a href='/privacy' style={{textDecoration: 'underline'}}>Learn more</a> </p>
          <button style={{position: 'absolute', bottom: 0, right: 0, marginBottom: 20, width: '150px', height: '37px', marginRight: 10}} onClick={hideCookieMenu}>Got it.</button>
        </div>
      }
    </>
  );
}

export default Home;