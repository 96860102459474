import React, { useEffect, useState } from 'react'
import { TextField, createMuiTheme, Button, Select, Radio, RadioGroup, FormLabel, FormControl, FormControlLabel } from '@material-ui/core'
import { ThemeProvider } from "@material-ui/styles";
import SnackBar from '../SnackBar/SnackBar'
import firebase from '../../firebase'
import CircularProgress from '@material-ui/core/CircularProgress'

const BookScreenComponent = () => {
    
    const theme = createMuiTheme({
        palette: {
            type: "dark"
        }
    })

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastname] = useState("")
    const [email, setEmail] = useState("") 
    const [sales, setSales] = useState("")
    const [phone, setPhone] = useState("")
    const [amazonFBA, setAmazonFBA] = useState("")
    const [participate, setParticipate] = useState("")
    const [budget, setBudget] = useState("")
    const [bookingDate, setBookingDate] = useState("")

    const [open, setOpen] = useState(false);
    const [text, setText] = useState("")
    const [severity, setSeverity] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0,0)
        setBookingDate(formatDate())
    }, [])

    const formatDate = () => {
        let date = new Date()
        const month = date.getMonth() + 1
        const monthStr = month < 9 ? `0${month}` : `${month}`
        const minutes = date.getMinutes() < 9 ? `0${date.getMinutes()}` : date.getMinutes()
        return `${date.getFullYear()}-${monthStr}-${date.getDate()}T${date.getHours()}:${minutes}`
    }

    const snackBarMessage = (state, text, severity) => {
        setOpen(state)
        setText(text)
        setSeverity(severity)
    }

    const handleChange = event => {
        setSales(event.target.value)
    }

    const sendHandler = () => {
        if (firstName.length === 0) return snackBarMessage(true, "Please enter your first name", "error")
        else if (lastName.length === 0) return snackBarMessage(true, "Please enter your last name", "error")
        else if (email.length === 0) return snackBarMessage(true, "Please enter your email address", "error")
        else if (sales.length === 0) return snackBarMessage(true, "Please select sales price", "error")
        else if (phone.length === 0) return snackBarMessage(true, "Please enter your phone number", "error")
        else if (amazonFBA.length === 0) return snackBarMessage(true, "What do you already know about Amazon FBA?", "error")
        else if (participate.length === 0) return snackBarMessage(true, "Why do you want to participate in AMZ-Master?", "error")
        else if (budget.length === 0) return snackBarMessage(true, "Budget now available for your Amazon company?", "error")
        else if (loading) { return }
        else if (new Date(bookingDate).getTime() < new Date().getTime()) { return snackBarMessage(true, "Please select valid date and time", "error")}

        let bd = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' }).format(new Date(bookingDate))

        let obj = {
            first_name: firstName,
            last_name: lastName,
            amazon_fba: amazonFBA,
            booking_date: bd,
            booking_date_time: new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(bookingDate)),
            timestamp: new Date().getTime(),
            email, sales, phone, participate, budget
        }

        setLoading(true)

        checkBookingAvaliable(bd, () => {
            let doc = firebase.firestore().collection('bookings').doc()
            doc.set(obj).then(() => {
                sendEmail(doc.id)
            })
        })
    }

    const checkBookingAvaliable = (date, completion) => {
        let ref = firebase.firestore().collection('bookings').where('booking_date', '==', date).limit(1)
        ref.get().then(snap => {
            if(snap.docs.length > 0) {
                snackBarMessage(true, "There are no slot available for that day please pick other date", "error")
                setLoading(false)
            } else {
                completion()
            }
        })
    }

    const sendEmail = (id) => {
        const url = `https://us-central1-amz-master.cloudfunctions.net/booking_email?id=${id}`
        fetch(url).then(res => res.json()).then(obj => {
            refreshPage()
        }).catch(() => {
            refreshPage()
        })
    }

    const refreshPage = () => {
        snackBarMessage(true, "Submitted successfully, We will get in touch with you as soon as possible", "success")
        setTimeout(() => {
            window.location.reload()
        }, 3000)
    }

    return (
        
        <>
            <SnackBar open={open} setOpen={setOpen} text={text} severity={severity} />
            <ThemeProvider theme={theme}>
                <section style={{marginTop: "4em"}}>
                    <div className="bookMainContainerDiv">
                        <h5>Please fill out our short application form now so that we can optimally prepare for the first interview with you:</h5>
                        <p className="bookMainPara">Then choose your date for your first meeting.</p>
                        
                        <div className="bookInputContainer">
                            <TextInput
                                text="What's your first name?*"
                                value={firstName}
                                SetValue={setFirstName}
                            />

                            <TextInput
                                text="What's your last name?*"
                                value={lastName}
                                SetValue={setLastname}
                            />

                            <TextInput
                                text="What email can we use to contact you?*"
                                value={email}
                                SetValue={setEmail}
                            />

                            <TextInput
                                text="What's your cell phone number?*"
                                value={phone}
                                SetValue={setPhone}
                            />

                            <div className="bookTextInputContainerDiv">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">What is your monthly sales target?*</FormLabel>
                                    <RadioGroup aria-label="sales" name="sales1" value={sales} onChange={handleChange}>
                                        <FormControlLabel value="1000 - 5000€" control={<Radio color="primary" />} label="1000 - 5000€" />
                                        <FormControlLabel value="5000 - 10000€" control={<Radio color="primary" />} label="5000 - 10000€" />
                                        <FormControlLabel value="10000 - 25000€" control={<Radio color="primary" />} label="10000 - 25000€" />
                                        <FormControlLabel value="25000 - 50000€" control={<Radio color="primary" />} label="25000 - 50000€" />
                                        <FormControlLabel value="Over 50,000€" control={<Radio color="primary" />} label="Over 50,000€" />
                                        
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            <TextInput
                                text="What do you already know about Amazon FBA?"
                                value={amazonFBA}
                                SetValue={setAmazonFBA}
                            />

                            <TextInput
                                text="Why do you want to participate in AMZ-Master?"
                                value={participate}
                                SetValue={setParticipate}
                            />

                            <TextInput
                                text="Establishing a new Amazon FBA company requires around € 13,000 in start-up capital - How high is your budget now available for your Amazon company?"
                                value={budget}
                                SetValue={setBudget}
                            />
                            
                            <div className="bookTextInputContainerDiv">
                                <p>{"Please pick the booking appointment time"}</p>
                                <TextField
                                    id="datetime-local"
                                    label="Appointment time"
                                    type="datetime-local"
                                    defaultValue={formatDate()}
                                    style={{width: '200', color: 'white'}}
                                    InputLabelProps={{ shrink: true}}
                                    onChange={(e => setBookingDate(e.target.value))}
                                />
                            </div>

                            <div className="contacts-btn-div">  
                                <Button color="primary" variant="contained" onClick={sendHandler}>Send</Button>
                            </div>

                            <br />
                            { loading ? <center> <CircularProgress /> </center> : null }
                        </div>
                    </div>
                </section>
            </ThemeProvider>
        </>
    )
}

const TextInput = props => {
    return (
        <div className="bookTextInputContainerDiv">
            <p>{props.text}</p>
            <TextField style={{ width: "85%" }} value={props.value} onChange={event => props.SetValue(event.target.value)} />
        </div>
    )
}


export default BookScreenComponent