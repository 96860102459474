import React, {useEffect} from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import ContactUs from '../components/layout/ContactUs';
import Fade from 'react-reveal/Fade';

const ContactScreen = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <>
      <Header />
      <Fade>
        <ContactUs />
      </Fade>
      <Footer />
    </>
  );
}

export default ContactScreen;