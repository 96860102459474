import firebase from 'firebase/app'
require('firebase/firestore')

var firebaseConfig = {
    apiKey: "AIzaSyCiMcJMI9VeLsQyQS3cUQJ9y9GhHt9CD2U",
    authDomain: "amz-master.firebaseapp.com",
    projectId: "amz-master",
    storageBucket: "amz-master.appspot.com",
    messagingSenderId: "66848138544",
    appId: "1:66848138544:web:cfcef391bda8063b0e14f5",
    measurementId: "G-H7LKPW10PP"
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export default firebase;