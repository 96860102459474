import React from 'react'
import Image from '../elements/Image'

const WareHousingScreen = () => {
    return (
        <div> 
            <div>
                <h2 className="image-header">Storage, Logistics and Fufilment</h2>
                <div className="image-contact-div">
                    <img className="image-contacts" src={require("../../assets/images/warehouse.jpg")} alt="" />
                </div> 
            </div>

            <div style={{ textAlign: "center" }}>
                <h3>Amazon FBA Preparation &amp; Storage services for you in Germany</h3>
                <p className="content-text">High quality solutions for your success on Amazon.de</p>
                <h5 className="content-text">Via our Pre FBA Service, we provide individual solutions to our customers. If required, we would behappy to handle your entire logistics process.</h5>

                <p className="content-text">Amz-Master provides exclusive Amazon FBA Prep services for Amazon Sellers. We help to store, label, re-pack, bundle, and ship to FBA Warehouses in Germany.</p>
            </div>
            
            <div style={{ display: "block", margin: "10px 15%" }}>
                <p>We fully understand your challenges…</p>
                <ul>
                    <li>High transportation cost</li>
                    <li>Strict packaging &amp; labelling requirements from Amazon</li>
                    <li>Inventory management process and associated cost</li>
                    <li>Shorter reaction times</li>
                </ul>
            </div>

            <div style={{ textAlign: "center" }}>
                <h3>Pre-FBA process Flow</h3>
                {/* reveal-from-bottom */}
                <div style={{display: "flex", justifyContent: "center", alignContent: "center", flexWrap: "wrap"}}>
                    <div className="tiles-item " data-reveal-delay="200"  onClick={() => window.location.replace('/accounting')}>
                        <div className="tiles-item-inner">
                            <div className="features-tiles-item-header">
                                <div className="features-tiles-item-image mb-16" style={{width: 64, height: 64, justifyContent: 'center'}}>
                                    <Image
                                        src={require('./../../assets/images/accounting.svg')}
                                        alt="Features tile icon 02"
                                        width={32}
                                        height={32}
                                    />
                                </div>
                            </div>
                            <div className="features-tiles-item-content">
                            <h4 className="mt-0 mb-8">We transport and receive your products</h4>
                                <p className="m-0 text-sm">
                                    You deliver or we pick up your products and transport them to our warehouse. From this moment onwards you can track the status of your products
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="tiles-item " data-reveal-delay="200"  onClick={() => window.location.replace('/accounting')}>
                        <div className="tiles-item-inner">
                            <div className="features-tiles-item-header">
                            <div className="features-tiles-item-image mb-16" style={{width: 64, height: 64, justifyContent: 'center'}}>
                                <Image
                                src={require('./../../assets/images/accounting.svg')}
                                alt="Features tile icon 02"
                                width={32}
                                height={32} />
                            </div>
                            </div>
                            <div className="features-tiles-item-content">
                            <h4 className="mt-0 mb-8">We take care of the packaging</h4>
                            <p className="m-0 text-sm">
                                We check, package, and label every article according to Amazon standards. All damaged products and defects are communicated immediately
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="tiles-item " data-reveal-delay="200"  onClick={() => window.location.replace('/accounting')}>
                        <div className="tiles-item-inner">
                            <div className="features-tiles-item-header">
                            <div className="features-tiles-item-image mb-16" style={{width: 64, height: 64, justifyContent: 'center'}}>
                                <Image
                                src={require('./../../assets/images/accounting.svg')}
                                alt="Features tile icon 02"
                                width={32}
                                height={32} />
                            </div>
                            </div>
                            <div className="features-tiles-item-content">
                            <h4 className="mt-0 mb-8">We send your product to Amazon</h4>
                            <p className="m-0 text-sm">
                            We send your products to the respective Amazong logistics warehouses and take care of the entire inventory management
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div style={{ display: "block", margin: "10px 15%" }}>
                <h5 style={{color: "red"}}>Our Services at glance:</h5>
                <ul>
                    <li>Inventory receiving, Cartons counting &amp; Inspection</li>
                    <li>Secure &amp; dry Storage</li>
                    <li>24/7 video monitoring</li>
                    <li>Standard prep for FBA (Visual Inspection of each unit, Sticker Covering/Removal, Box Level Content, Damage Item Handling, FBA/Carrier label application)</li>
                    <li>FNSKU Labeling</li>
                    <li>Oversized/Overweight Inventory processing</li>
                    <li>Additional packaging (poly bag, bubble wrap etc.)</li>
                    <li>Re-packaging</li>
                </ul>

                <div>
                    <p>For prices check out the <a style={{borderBottom: "1px solid #fff"}} href="/prices">Pricing</a></p>
                </div>
            </div>

            <div style={{textAlign: "center"}}>
                <button className="teams-contact-btn" onClick={() => window.location.replace('contact')}>Get More Information</button>
            </div>

        </div>
    )
}

export default WareHousingScreen