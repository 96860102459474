import React, { useEffect } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import LogisticsScreenComponent from '../components/layout/LogisticsScreenComponent';
import Fade from 'react-reveal/Fade';

const LogisticsScreen = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return (
    <>
        <Header />
          <Fade>
            <LogisticsScreenComponent />
          </Fade>
        <Footer />
    </>
  );
}

export default LogisticsScreen;