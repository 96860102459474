import { Button, Checkbox, createMuiTheme, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { ThemeProvider } from "@material-ui/styles";
import Grid from '@material-ui/core/Grid';
import CountrySelect from './CountrySelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import SnackBar from '../SnackBar/SnackBar'
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from '../../firebase'
import WareHousingScreen from './WareHousingScreen';
import AccountingTaxationScreen from './AccountingTaxationScreen';
import PriceList from './PriceList';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const styles = {
    textInput: {
        display: "block",
        margin: "1em",
        width: "100%"
    }
}

const ContactUs = () => {
    
    const classes = useStyles();

    const theme = createMuiTheme({
        palette: {
            type: "dark"
        }
    })
    
    const [checked, setChecked] = useState(false)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [companyEmail, setCompnayEmail] = useState("")
    const [jobTitle, setJobTitle] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [comment, setComment] = useState("")

    const [country, setCountry] = useState("")
    const [businessName, setBusinessName] = useState(0)

    const [open, setOpen] = useState(false)
    const [text, setText] = useState("")
    const [severity, setSeverity] = useState("")
    const [loading, setLoading] = useState(false)

    const snackBarMessage = (state, text, severity) => {
        setOpen(state)
        setText(text)
        setSeverity(severity)
    }

    const handleChange = event => {
        setBusinessName(event.target.value)
    }

    const messageHandler = () => {
        if (firstName.length === 0) return snackBarMessage(true, "Please enter your first name", "error")
        else if (lastName.length === 0) return snackBarMessage(true, "Please enter your last name", "error")
        else if (companyName.length === 0) return snackBarMessage(true, "Please enter company name address", "error")
        else if (companyEmail.length === 0) return snackBarMessage(true, "Please enter company email address", "error")
        else if (jobTitle.length === 0) return snackBarMessage(true, "Please enter job title", "error")
        else if (phoneNumber.length === 0) return snackBarMessage(true, "Please phone number title", "error")
        else if (country.length === 0) return snackBarMessage(true, "Please pick country", "error")
        else if (businessName.length === 0) return snackBarMessage(true, "Please pick business name", "error")
        else if (checked === false) return snackBarMessage(true, "Please agree to our privacy policy", "error")
        else if (loading) { return }

        setLoading(true)

        let obj = {
            first_name: firstName,
            last_name: lastName,
            company_name: companyName,
            company_email: companyEmail,
            job_title: jobTitle,
            phone_number: phoneNumber,
            business_name: businessName,
            is_done: false,
            timestamp: new Date().getTime(),
            date: new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date()),
            comment, country
        }
        
        let doc = firebase.firestore().collection('contact_us').doc()
        
        doc.set(obj).then(() => {
            sendEmail(doc.id)
        })
    }

    const sendEmail = (id) => {
        const url = `https://us-central1-amz-master.cloudfunctions.net/contact_email?id=${id}`
        fetch(url).then(res => res.json()).then(obj => {
            refreshPage()
        }).catch(() => {
            refreshPage()
        })
    }

    const refreshPage = () => {
        snackBarMessage(true, "Submitted successfully, We will get in touch with you as soon as possible", "success")
        setTimeout(() => {
            window.location.reload()
        }, 3000)
    }
    
    const checkBoxHandler = value => {
        setChecked(value.target.checked)
    }

    return (
        <ThemeProvider theme={theme}>
            <SnackBar open={open} setOpen={setOpen} text={text} severity={severity} />
            <section style={{ marginTop: 40 }}>
                <div>
                    <h2 className="image-header">Contact Us</h2>
                    <div className="image-contact-div">
                        <img className="image-contacts" src={require("../../assets/images/contactus.jpg")} alt="" />
                    </div> 
                </div>

                <h3 style={{textAlign: "center"}}>Let's Start a Conversation</h3>

                <div id="contact-main-div">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={5}>
                            <div>
                                <h4>Ask how we can help you:</h4>

                                <div>
                                    <div>
                                        <h6>See our platform in action</h6>
                                        <p>Request a private appointment, or contact us by either filling out the form or send us an Email.</p>
                                        {/* <p>Request a personalized demo, or sign up for
                                            <a style={{color: "#007AFF"}} className="contact-links" href="https://google.com/">a 30-day free trial</a>
                                        of TUNE’s performance marketing platform.</p> */}
                                    </div>

                                </div>

                                <h4>Points of Contact:</h4>

                                <div>
                                    <div>
                                        <h6>Munich, Germany | AMZ-MASTER Headquarters</h6>
                                        {/* <p>2200 Western Ave, Suite 200, Seattle, WA 98121206.508.1318</p> */}
                                    </div>

                                    <div>
                                        <h6>Information and Sales</h6>
                                        <a href="mailto:info@amz-master.com" style={{color: "#007AFF"}} className="contact-links">info@amz-master.com</a>
                                        <p>Whatsapp:  +49 173 3253093</p>
                                    </div>

                                    
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={2} />
                        <Grid item xs={12} sm={5}>
                            <div className="input-content-main-div">
                                <h6 style={{marginLeft: "1.2em"}}>Please note: all fields are required</h6>
                                <div className="input-contacts-div">
                                    <TextField fullWidth={true} style={{...styles.textInput, maxWidth: "26em"}} id="first-name" label="First Name" variant="outlined" value={firstName} onChange={value => setFirstName(value.target.value)}  />
                                    <TextField fullWidth={true} style={{...styles.textInput, maxWidth: "26em"}} id="last-name" label="Last Name" variant="outlined" value={lastName} onChange={value => setLastName(value.target.value)} />
                                    <TextField fullWidth={true} style={{...styles.textInput, maxWidth: "26em"}} id="company-name" label="Company Name" variant="outlined" value={companyName} onChange={value => setCompanyName(value.target.value)} />
                                    <TextField fullWidth={true} style={{...styles.textInput, maxWidth: "26em"}} id="company-email" label="Company Email" variant="outlined" value={companyEmail} onChange={value => setCompnayEmail(value.target.value)} />
                                    <TextField fullWidth={true} style={{...styles.textInput, maxWidth: "26em"}} id="job-title" label="Job Title" variant="outlined" value={jobTitle} onChange={value => setJobTitle(value.target.value)} />
                                    <TextField fullWidth={true} style={{ ...styles.textInput, maxWidth: "26em" }} id="phone-number" label="Phone Number" variant="outlined" value={phoneNumber} onChange={value => setPhoneNumber(value.target.value)} />
                                    <TextField fullWidth={true} style={{ ...styles.textInput, maxWidth: "26em" }} id="comment" label="Comments" variant="outlined" value={comment} onChange={value => setComment(value.target.value)} />
                                    <CountrySelect country={country} setCountry={setCountry} />
                                    <div style={{marginLeft: "0.8em"}}>
                                        <FormControl variant="filled" className={classes.formControl}>
                                        <InputLabel  htmlFor="filled-age-native-simple">Bussiness</InputLabel>
                                            <Select
                                                fullWidth={true}
                                                native
                                                value={businessName}
                                                onChange={handleChange}
                                                inputProps={{
                                                    name: 'age',
                                                    id: 'filled-age-native-simple',
                                                }}
                                            >
                                            <option aria-label="None" value="" />
                                            <option value={"Amazon FBA"}>Amazon FBA</option>
                                            <option value={"Accounting & Taxation"}>Accounting & Taxation</option>
                                            <option value={"Trademark & Branding"}>Trademark & Branding</option>
                                            <option value={"Logistics pre-Fba-prep"}>Logistics pre-Fba-prep</option>
                                        </Select>
                                        </FormControl>
                                    </div>

                                    <div style={{ marginLeft: "0.5em", display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                                        <Checkbox
                                            style={{width: 40}}
                                            color="primary"
                                            checked={checked}
                                            onChange={checkBoxHandler}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                        <div style={{marginTop: "0.5em" }}>
                                            <p style={{fontSize: 16}}>I'd like to receive more information about ABC; I understand and agree to the
                                                <a href="/privacy" style={{color: "#007AFF"}} className="contact-links">privacy policy.</a>
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="contacts-btn-div" style={{marginBottom: 30}}>  
                                    <Button color="primary" variant="contained" onClick={messageHandler}>Send Message</Button>
                                </div>
                                <br />
                                { loading ? <center> <CircularProgress /> </center> : null }
                            </div>
                        </Grid>
                    </Grid>
                </div>
                </section>
        </ThemeProvider>
    )
}

export default ContactUs

// <section style={{ marginTop: 100 }}>
//    {/* <WareHousingScreen /> */}
//    {/* <AccountingTaxationScreen /> */}
//    {/* <PriceList /> */}
// </section>