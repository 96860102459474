import React from 'react'
import { createMuiTheme } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/styles";
import Grid from '@material-ui/core/Grid';

const TeamScreenComponent = () => {

    const info = {
        text: ''
    }
    const teams = [{
        name: "Zeeshan Sabir",
        service: "Founder, Senior Consultant & Strategist",
        desc: "Zeeshan is the managing director and founder of the AMZMASTER brand. Since 2015 he has been successfully running various E-Commerce businesses on Amazon, Ebay and other platforms. He is one of the leading Amazon FB experts in Germany and UK. Motivated by the vision of achieving time & location independence, he helps general public across the globe to build successful online businesses especially Amazon FBA. He works tirelessly to develop & implement innovative ideas and solutions so that everyone could benefit to a maximum extent.",
        image: require('../../assets/images/member1.JPG')
    }, {
        name: "Irfan Anwer Pasha",
        service: "Co-Founder, Senior Consutant & Strategist",
        desc: "Irfan is the co-founder and senior strategist. In his previous career activities, he worked for the national logistics company in Pakistan but realized quickly that setting up an Amazon FBA business has far reaching advantages in terms of financial and location freedom. He is also a strong advocate of time and location freedom and supports the vision of AMZMASTER. Today Irfan is responsible for implementation of latest strategic procedures for our company. He provides full-fledged support to our valued clients beyond their needs and fully help them in growing their businesses and building their brands.",
        image: require('../../assets/images/member2.jpg')
        }, {
        name: "Farheen Zeeshan",
        service: "Director of Client Success",
        desc: "Mother of two kids and always ready to create great customer experiences. She is the expert of product hunting and sourcing. She is also an expert to handle the complete launch of the products. Amazing mother with amazing work expertise. She is workaholic and always available to support and make succeed every project of the clients of the company.",
        image: require('../../assets/images/member_none.png')
        },
        {
            name: "Aneeq Essani",
            service: "Legal Entity & Amazon Account  Specialist",
            desc: "With years of experience under his belt, Aneeq is a very valuable asset for AMZMASTER team. He specializes in company and Amazon account formation.",
            image: require('../../assets/images/member_none.png')
        },
        {
            name: "Wajeeha Shafqat",
            service: "Manager marketing and ranking",
            desc: "You can define Wajeeha as marketing and ranking expert, department head, always full of energy and ready for work. PPC expert, keyword analyzer and a great strategist.",
            image: require('../../assets/images/member_none.png')
        },
        {
            name: "Maheen Shafqat",
            service: "Assistant manager marketing and ranking",
            desc: "Maheen is the ranking expert of Amazon products. She has started working 2 years back and now she has a great team. Her expertise are growing with the time in her domain. She will be responsible for implementation of ranking strategies like affiliate marketing, social media marketing, and influencer marketing.",
            image: require('../../assets/images/member_none.png')
        }]
    
    const employees = [{
        name: "Annabelle E.",
        image: require('../../assets/images/employee_none.png')
    }, {
            name: "Christian S.",
            image: require('../../assets/images/employee_none.png')
        }, {
            name: "Jennifer S.",
            image: require('../../assets/images/employee_none.png')
        }, {
            name: "Marvin K.",
            image: require('../../assets/images/employee_none.png')
        }
    ]

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const theme = createMuiTheme({
        palette: {
            type: "dark"
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <section style={{ marginTop: 40 }}>

                <h2 className="teamsHeader">About Us</h2>
                <p style={{color: 'white', margin: 50}}>The foundation stone of Amz-Master was laid in 2019 by Zeeshan Sabir and Irfan Anwer Pasha. Both have been successfully selling on Amazon and other marketplaces for over 5 years and are considered pioneers in the E-commerce business. While setting up their own Amazon FBA businesses, both had always the motto to build an online business which could give them time, location & money independence. Keeping this motto in mind, since then the two entrepreneurs have devoted themselves to empower more and more people in building the Amazon FBA business. Over the time they have built a team of over 20 experts in Germany, UK and Pakistan for Aamzon FBA business and now the Amz-Master platform is end product where people from across the globe can benefit from premium quality knowledge and A-Z services.</p>

                <Grid
                    direction="row"
                    alignItems="center"
                    justify="center"
                    container
                    spacing={3}
                >
                    <>
                        <Grid item xs={11} md={5}  >
                            <div className="team-image-div">
                                <img className="team-image" src={teams[0].image} alt="member1" />
                            </div>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={11} md={5} >
                            <TeamMemberInfo
                                name={teams[0].name}
                                service={teams[0].service}
                                desc={teams[0].desc}
                            />
                        </Grid>
                    </>
                    <>
                        {isMobile ? 
                        
                            <>
                                <Grid  item xs={11} md={5}>
                                    <div className="team-image-div">
                                        <img className="team-image" src={teams[1].image} alt="" />
                                    </div>
                                </Grid>
                                    <Grid item xs={1} />
                                <Grid  item xs={11} md={5}>
                                    <TeamMemberInfo
                                        name={teams[1].name}
                                        service={teams[1].service}
                                        desc={teams[1].desc}
                                    />
                                </Grid>
                            </>
                            :
                            <>
                                <Grid className="team-image-div"item xs={11} md={5}>
                                    <TeamMemberInfo
                                        name={teams[1].name}
                                        service={teams[1].service}
                                        desc={teams[1].desc}
                                    />
                                </Grid>
                                <Grid item xs={1} />
                                <Grid  item xs={11} md={5}>
                                    <div>
                                        <img className="team-image" src={teams[1].image} alt="" />
                                    </div>
                                </Grid>
                            </>
                        }
                    </>
                    <>
                        <Grid  item xs={11} md={5}>
                            <div className="team-image-div">
                                <img className="team-image" src={require("../../assets/images/member_none.png")} alt="" />
                            </div>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid  item xs={11} md={5}>
                            <TeamMemberInfo
                                name={teams[2].name}
                                service={teams[2].service}
                                desc={teams[2].desc}
                            />
                        </Grid>
                    </>
                    
                    {isMobile ? 
                        
                        <>
                            <Grid  item xs={11} md={5}>
                                <div className="team-image-div">
                                    <img className="team-image" src={require("../../assets/images/member_none.png")} alt="" />
                                </div>
                            </Grid>
                                <Grid item xs={1} />
                            <Grid  item xs={11} md={5}>
                                <TeamMemberInfo
                                    name={teams[3].name}
                                    service={teams[3].service}
                                    desc={teams[3].desc}
                                />
                            </Grid>
                        </>
                        :
                        <>
                            <Grid className="team-image-div"item xs={11} md={5}>
                                <TeamMemberInfo
                                    name={teams[3].name}
                                    service={teams[3].service}
                                    desc={teams[3].desc}
                                />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid  item xs={11} md={5}>
                                <div>
                                    <img className="team-image" src={require("../../assets/images/member_none.png")} alt="" />
                                </div>
                            </Grid>
                        </>
                    }
                    
                    <>
                        <Grid  item xs={11} md={5}>
                            <div className="team-image-div">
                                <img className="team-image" src={require("../../assets/images/member_none.png")} alt="" />
                            </div>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid  item xs={11} md={5}>
                            <TeamMemberInfo
                                name={teams[4].name}
                                service={teams[4].service}
                                desc={teams[4].desc}
                            />
                        </Grid>
                    </>

                    {isMobile ? 
                        
                        <>
                            <Grid  item xs={11} md={5}>
                                <div className="team-image-div">
                                    <img className="team-image" src={require("../../assets/images/member_none.png")} alt="" />
                                </div>
                            </Grid>
                                <Grid item xs={1} />
                            <Grid  item xs={11} md={5}>
                                <TeamMemberInfo
                                    name={teams[5].name}
                                    service={teams[5].service}
                                    desc={teams[5].desc}
                                />
                            </Grid>
                        </>
                        :
                        <>
                            <Grid className="team-image-div"item xs={11} md={5}>
                                <TeamMemberInfo
                                    name={teams[5].name}
                                    service={teams[5].service}
                                    desc={teams[5].desc}
                                />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid  item xs={11} md={5}>
                                <div>
                                    <img className="team-image" src={require("../../assets/images/member_none.png")} alt="" />
                                </div>
                            </Grid>
                        </>
                    }


                    {/* <Grid item xs={12}>
                        {isMobile ? <h3 style={{marginLeft: "1em"}}>Teamleitung</h3> : <h3 style={{marginLeft: "3em"}}>Teamleitung</h3> } 
                    </Grid>
                    <>
                        {!isMobile ? 
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <EmployeeInfo
                                    xs={4} md={3}
                                    image={employees[0].image}
                                    name={employees[0].name}
                                />
                                <EmployeeInfo
                                    xs={4} md={3}
                                    image={employees[1].image}
                                    name={employees[1].name}
                                />
                                <EmployeeInfo
                                    xs={4} md={3}
                                    image={employees[2].image}
                                    name={employees[2].name}
                                />
                                <EmployeeInfo
                                    xs={4} md={3}
                                    image={employees[3].image}
                                    name={employees[3].name}
                                />
                            </div>
                            :
                            <>
                                <>
                                    <EmployeeInfo
                                        xs={6} md={6}
                                        image={employees[0].image}
                                        name={employees[0].name}
                                    />
                                    <EmployeeInfo
                                        xs={6} md={6}
                                        image={employees[1].image}
                                        name={employees[1].name}
                                    />
                                </>
                                <>
                                    <EmployeeInfo
                                        xs={6} md={6}
                                        image={employees[2].image}
                                        name={employees[2].name}
                                    />
                                    <EmployeeInfo
                                        xs={6} md={6}
                                        image={employees[3].image}
                                        name={employees[3].name}
                                    />
                                </>
                            </>
                        }
                    </> */}
                </Grid>

                <div style={{textAlign: "center"}}>
                    <button className="teams-contact-btn" onClick={() => window.location.replace('contact')}>Contact Us</button>
                </div>
            </section>
        </ThemeProvider>
    )
}

const TeamMemberInfo = props => {
    return (
        <div>
            <h3>{props.name}</h3>
            <p className="team-service">{props.service}</p>
            <div>
                <p>{props.desc}</p>
            </div>
        </div>
    )
}

const EmployeeInfo = props => {
    return (
        <Grid  item xs={props.xs} md={props.md}>
            <div style={{margin: "0px 0.8em"}}>
                <img className="team-image-footer" src={props.image} alt="" />
                <h5 style={{marginLeft: "4px", marginTop: "5px"}}>{props.name}</h5>
            </div>
        </Grid>
    )
}

export default TeamScreenComponent