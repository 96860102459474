import React, { useEffect } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import TradeScreenComponent from '../components/layout/TradeScreenComponent';
import Fade from 'react-reveal/Fade';

const TrademarkScreen = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return (
    <>
        <Header />
          <Fade>
            <TradeScreenComponent />
          </Fade>
        <Footer />
    </>
  );
}

export default TrademarkScreen;