import React from 'react'

export default class PrivacyScreenComponent extends React.Component {
    render() {
        return(
            <section style={{marginTop: 40}}>
                
                <div>
                    <h2 className="image-header">Privacy Policy</h2>
                    <div className="image-contact-div">
                        <img className="image-contacts" src={require("../../assets/images/contactus.jpg")} alt="" />
                    </div> 
                </div>

                <div style={{margin: 50}}>
                    <p style={{color: 'white'}}><b>Effective April 26, 2019</b></p>
                    <p style={{color: 'darkgray'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse egestas mattis nisl, a imperdiet velit pretium vitae. Pellentesque vulputate gravida ex in blandit. Morbi efficitur sem suscipit ex mattis rhoncus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed vel ipsum sapien. Integer nec nibh at felis vehicula efficitur ac at urna. Suspendisse vel dictum ligula, at faucibus neque. Donec porttitor semper tortor, nec vehicula elit ullamcorper nec. Etiam eu viverra nibh, vel venenatis mi. Praesent vitae ornare turpis.</p>
                    
                    <br />
                    <p style={{color: 'white'}}><b>TUNE Platform and Data Pledge</b></p>
                    <p style={{color: 'darkgray'}}>Praesent eleifend velit at lacinia consequat. Morbi eget pharetra turpis, vitae dignissim metus. Nulla fermentum, est ac posuere blandit, ex justo semper tellus, at condimentum augue ante convallis libero. Sed sed posuere orci. Praesent consequat sit amet diam sed pretium. Vivamus placerat interdum magna, quis lobortis dui finibus ac. Vivamus blandit dapibus elementum. Vivamus auctor finibus dapibus. Sed aliquam dignissim nunc, dignissim mattis sapien cursus id. Suspendisse lobortis urna nec vulputate eleifend. Quisque ornare cursus maximus. Praesent sed lectus vel orci porttitor blandit.</p>

                    <br />
                    <p style={{color: 'white'}}><b>What Information Does TUNE Collect From Clients?</b></p>
                    <p style={{color: 'darkgray'}}>Praesent eleifend velit at lacinia consequat. Morbi eget pharetra turpis, vitae dignissim metus. Nulla fermentum, est ac posuere blandit, ex justo semper tellus, at condimentum augue ante convallis libero. Sed sed posuere orci. Praesent consequat sit amet diam sed pretium. Vivamus placerat interdum magna, quis lobortis dui finibus ac. Vivamus blandit dapibus elementum. Vivamus auctor finibus dapibus. Sed aliquam dignissim nunc, dignissim mattis sapien cursus id. Suspendisse lobortis urna nec vulputate eleifend. Quisque ornare cursus maximus. Praesent sed lectus vel orci porttitor blandit.</p>
                </div>
            </section>
        )
    }
}