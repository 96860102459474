import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#6F1FFA',
        color: theme.palette.common.white,
  },
  body: {
      fontSize: 14,
      color: "#fff"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#25282C',
        },
      '&:nth-of-type(even)': {
            backgroundColor: '#25282C',
        },
  },
}))(TableRow);

function createData(name, price) {
  return { name, price };
}

const rows = [
    createData('Hunting a winning product for Amazon FBA (US, UK, EU marketplaces)', '€ 400'),
    createData('Complete Product Sourcing Incl. Samples Checking', '€ 300'),
    createData('On-Site Goods inspection; Quality Control (Available in Germany, China, Pakistan)', '€ 200'),
    createData('Organizing a premium, cheap Ocean and Air Freight Forwarding', 'Available on Demand'),
    createData('Professional Product Photography', '€ 500 per shoot'),
    createData('Professional Product Video Shoot and Editing', '€ 500 per shoot'),
    createData('Creation of a keywords optimized Amazon Listing (Title, Bullet points, Description)', '€ 100'),
    createData('Professional Enhanced Brand Content creation', '€ 350'),
    createData('A to Z Amazon FBA product Launching, Ranking (From launch date to next 2 months)', '€ 2000'),
    createData('Professional PPC Campaigns creation and Optimization of existing campaigns', '€ 100/Week'),
];

const taxRows = [
    createData('Complete German VAT registration (Tax Number, VAT ID, EORI number)', '€ 200'),
    createData('Monthly German VAT Filing', '€ 50'),
    createData('Quarterly subscription for German VAT Filing', '€ 150'),
    createData('Yearly subscription for German VAT Filing', '€ 500'),
    createData('VAT Registration for UK', '€ 75'),
    createData('Quarterly subscription for UK VAT Filing', '€ 150'),
    createData('Yearly subscription for UK VAT Filing', '€ 500'),
    createData('VAT registration for France, Italy, Spain, Sweden, Netherlands, Czech Republic and Poland', '€ 350 per Country'),
    createData('VAT filings for France, Italy, Spain, Sweden, Netherlands, Czech Republic and Poland', 'Starting from € 75 per filing'),
];

const brandingRows = [
    createData('German Trademark Registration (Incl. Protectability verification, Identity and Similarity Checks, Classes Identification and Filing with DPMA)', '€ 689 (up to 3 Classes) Incl. DPMA fee of 290€'),
    createData('UK Trademark Registration (Incl. Protectability verification, Identity &amp; Similarity Checks, Classes Identification and Filing with UK IPO)', '€ 300 (1 Class) € 70 for each additional class Incl. UK IPO Fee'),
    createData('EU Trademark Registration (Incl. Protectability verification, Identity &amp; Similarity Checks, Classes Identification and Filing with EU IPO)', '€ 1299 Incl. EUIPO Fee of € 850 for one class'),
]

const warehousingRows = [
    createData('Inventory receiving, Cartons counting and Inspection, Secure and dry Storage (Applicable to standard sized cartons), 24/7 video monitoring. NOTE: Charges calculation is done on weekly basis meaning if for any week the amount of consumed space is decreased or increased then charges will be retrospectively adjusted in the next monthly invoice and as per need the Debit/Credit note will be issued to the customer.', '€ 25 per Cubic Meter (cbm)'),
    createData('Standard prep for FBA (Visual Inspection of each unit, Sticker Covering/Removal, Box Level Content, Damage Item Handling, FBA/Carrier label application) Incl. Shipping to Amazon’s warehouse (DHL, UPS)', '€ 2.5 per Carton'),
    createData('FNSKU Labeling', '€ 0.30/Unit'),
    createData('Oversized/Overweight Inventory processing', 'Standard fee + €0.50/Unit'),
    createData('Pictures of the inventory, thorough inspection', 'Upon request'),
    createData('Additional packaging (poly bag, bubble wrap etc.)', 'Upon request'),
    createData('20” Container unload', '€ 200'),
    createData('40” Container unload', '€ 350'),
    createData('45” Container unload', '€ 350'),
    createData('New Cartons for Re-Packaging (S, M, L, XL, XXL, XXL)', 'Upon request'),
]

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const PriceList = () => {

    const classes = useStyles();

    return (
        <>
            
            <h2 style={{textAlign: "center"}}>Price List</h2>

            <div className="priceListtableDiv">
                <p style={{color: "#6163FF", fontWeight: 'bold', marginLeft: '17vw'}}>Core Amazon FBA Services</p>
                <TableContainer style={{borderRadius: "10px", borderColor: "red", maxWidth: '800px', margin: '0px auto'}}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead style={{margin: "20px"}}>  
                            <TableRow>
                                <StyledTableCell>
                                    <p style={{margin: "0px 20px"}}>Service Name</p>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <p style={{margin: "0px 20px"}}>Price</p>
                                </StyledTableCell>
                            </TableRow>
                            </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">                                    
                                    <p style={{margin: "0px 20px"}}>{row.name}</p>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <p style={{margin: "0px 20px"}}>{row.price}</p>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <div className="priceListtableDiv">
                <p style={{color: "#6163FF", fontWeight: 'bold', marginLeft: '17vw'}}>Accounting &amp; Taxation</p>
                <TableContainer style={{borderRadius: "10px", maxWidth: '800px', margin: '0px auto'}}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead style={{margin: "20px"}}>  
                            <TableRow>
                                <StyledTableCell>
                                    <p style={{margin: "0px 20px"}}>Service Name</p>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <p style={{margin: "0px 20px"}}>Price</p>
                                </StyledTableCell>
                            </TableRow>
                            </TableHead>
                        <TableBody>
                        {taxRows.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">                                    
                                    <p style={{margin: "0px 20px"}}>{row.name}</p>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <p style={{margin: "0px 20px"}}>{row.price}</p>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <div className="priceListtableDiv">
                <p style={{color: "#6163FF", fontWeight: 'bold', marginLeft: '17vw'}}>Trademark &amp; Branding</p>
                <TableContainer style={{borderRadius: "10px", maxWidth: '800px', margin: '0px auto'}}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead style={{margin: "20px"}}>  
                            <TableRow>
                                <StyledTableCell>
                                    <p style={{margin: "0px 20px"}}>Service Name</p>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <p style={{margin: "0px 20px"}}>Price</p>
                                </StyledTableCell>
                            </TableRow>
                            </TableHead>
                        <TableBody>
                        {brandingRows.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">                                    
                                    <p style={{margin: "0px 20px"}}>{row.name}</p>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <p style={{margin: "0px 20px"}}>{row.price}</p>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <div className="priceListtableDiv">
                <p style={{color: "#6163FF", fontWeight: 'bold', marginLeft: '17vw'}}>Warehousing &amp; 3PL</p>
                <TableContainer style={{borderRadius: "10px", maxWidth: '800px', margin: '0px auto'}}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead style={{margin: "20px"}}>  
                            <TableRow>
                                <StyledTableCell>
                                    <p style={{margin: "0px 20px"}}>Service Name</p>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <p style={{margin: "0px 20px"}}>Price</p>
                                </StyledTableCell>
                            </TableRow>
                            </TableHead>
                        <TableBody>
                        {warehousingRows.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">                                    
                                    <p style={{margin: "0px 20px"}}>{row.name}</p>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <p style={{margin: "0px 20px"}}>{row.price}</p>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            
            <div style={{display: "flex", justifyContent: "center" }}>
                 <div style={{textAlign: "center", margin: '5px'}}>
                    <button className="teams-contact-btn" onClick={() => window.location.replace('book')}>Book an appointement</button>
                </div>
                <div style={{textAlign: "center", margin: '5px'}}>
                    <button className="teams-contact-btn" onClick={() => window.location.replace('contact')}>Contact Us</button>
                </div>
            </div>
        </>
        
    )
}

export default PriceList
