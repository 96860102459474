import React from 'react'
import { createMuiTheme, Table, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { ThemeProvider } from "@material-ui/styles";
import Grid from '@material-ui/core/Grid';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const trademarkResponsibility = [
    {
        title: "Verification of protectability",
        desc: "Our lawyers check whether the trademark you want is protectable and therefore registrable."
    },
    {
        title: "Identity research AND similarity research",
        desc: "We check whether an identical or similar trademark is already registered - this trademark search is included in the price!"
    },
    {
        title: "Directory of goods and services",
        desc: "Our lawyers create an individual list of goods and services tailored to your company and project."   
    },
    {
        title: "Registration of the trademark at the trademark office",
        desc: "After coordinating the trademark registration, we will register the trademark for you with the responsible trademark office."
    },
    {
        title: "After coordinating the trademark registration, we will register the trademark for you with the responsible trademark office.",
        desc: "You will receive your trademark application within 24 hours."
    }
]

const trademarkStamp = [
    {
        title: "German Trademark",
        price: "€ 399.00",
        desc: "gross, including VAT. Additional DPMA fee of € 290.00 for three classes",
        btn: "German Trademark"
    },
    {
        title: "EU (Union) Trademark",
        price: "€ 449.00",
        desc: "gross, including VAT. An additional € 850.00 EUIPO fee for one class",
        btn: "EU Trademark"
    },
]

const trademarkTable = [
    {
        text: "Protectability verification", // Überprüfung der Schutzfähigkeit
        icon1: true,
        icon2: true
    },
    {
        text: "Identity Research", // Identitätsrecherche
        icon1: true,
        icon2: true
    },
    {
        text: "Similarity research", // Ähnlichkeitsrecherche
        icon1: true,
        icon2: true
    },
    {
        text: "Classes identification for Goods and Services", // Erstellung Waren- und Dienstleistungsverzeichnis
        icon1: true,
        icon2: true
    },
    {
        text: "Legal checking and application", //  Rechtliche Prüfung der Eintragung
        icon1: true,
        icon2: true
    },
    {
        text: "Communication with patent office", //  Korrespondenz mit dem Patentamt
        icon1: true,
        icon2: true
    },
    // {
    //     text: "Anmeldung in 24 h",
    //     icon1: true,
    //     icon2: true
    // },
    {
        text: "Fixed Price", // Festpreis / Pauschalhonorar
        icon1: true,
        icon2: true
    },
    {
        text: "Registration with EU patent office (EUIPO)", //  Anmeldung beim Europäischen Patentamt (EUIPO)
        icon1: false,
        icon2: true
    },
    {
        text: "Registration with German patent office (DPMA)", //  Anmeldung beim Deutschen Patentamt
        icon1: true,
        icon2: false
    }
]

const TrademarkGrid = () => {
    
    const theme = createMuiTheme({
        palette: {
            type: "dark"
        }
    })

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <ThemeProvider theme={theme}>
            <section style={{ marginTop: 40 }}>
                <div id="contact-main-div">
                    <Grid container spacing={3}>
                        <>
                            <Grid item xs={12} md={6}>
                                {trademarkResponsibility.map((value, index) => (
                                    <TrademarkResponsibility key={index} title={value.title} desc={value.desc} />
                                ))}
                                <h5 className="trademark-header trademark-color">You can change or cancel your order at any time.</h5>
                            </Grid>
                            <Grid item xs={12} md={1} />
                            <Grid item xs={12} md={5}>
                                {trademarkStamp.map((value, index) => (
                                    <TrademarkStamp key={index} title={value.title} price={value.price} desc={value.desc} btn={value.btn} />
                                ))}
                            </Grid>
                        </>
                        <>
                            {isMobile ? 
                                <>
                                    <>
                                        <Grid item xs={12} sm={12}>
                                            <Table style={{marginLeft: "-2em"}}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell>
                                                            <Typography style={{marginLeft: "-1em", fontSize: "16px"}}>Deutsche Marke</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {trademarkTable.map((value, index) => (
                                                    <TrademarkTableMobile key={index} text={value.text} icon={value.icon1} />
                                                ))}
                                            </Table>   
                                        </Grid>
                                    </>
                                    <>
                                        <Grid item xs={12} sm={12}>
                                            <Table style={{marginLeft: "-2em"}}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell>
                                                            <Typography style={{marginLeft: "-1.5em", fontSize: "16px"}}>Europäische </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {trademarkTable.map((value, index) => (
                                                    <TrademarkTableMobile key={index} text={value.text} icon={value.icon2} />
                                                ))}
                                            </Table>   
                                        </Grid>
                                    </> 
                                </>
                            :
                                <>
                                    <Grid item xs={1} sm={1} />
                                    <Grid item xs={10} sm={10}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell>
                                                        <Typography>Deutsche Marke</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>Europäische</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {trademarkTable.map((value, index) => (
                                                <TrademarkTable key={index} text={value.text} icon1={value.icon1} icon2={value.icon2} />
                                            ))}
                                        </Table>   
                                        
                                    </Grid>
                                    <Grid item xs={1} sm={1} />
                                </>   
                            }
                        </>
                    </Grid>
                </div>
            </section>
        </ThemeProvider>
    )
}

const TrademarkResponsibility = props => {
    return (
        <>
            <div key={props.key} className="trademark-div">
                <CheckCircleRoundedIcon htmlColor="#5658DD" fontSize="large" />
                <h5 className="trademark-title">{props.title}</h5>
            </div>
            <p className="trademark-desc">{props.desc}</p>
        </>
    )
}

const TrademarkStamp = props => {
    return (
        <div className="trademark-stamp">
            <div key={props.key} style={{paddingTop: "0.1em", paddingBottom: "0.1em"}}>
                <h4 style={{padding: "0em 0.3em"}} className="trademark-color">{props.title}</h4>
                <h5 className="trademark-color">{props.price}</h5>
                <p className="trademark-desc trademark-color">{props.desc}</p>
            </div>
            <button className="trademark-btn">{props.btn}</button>
        </div>
    )
}

const TrademarkTable = props => {
    return (
        <TableRow>
            <TableCell>
                <Typography color="textPrimary" variant="body1">{props.text}</Typography>
            </TableCell>
            <TableCell>
                {props.icon1 && <CheckCircleRoundedIcon htmlColor="#5658DD" fontSize="small" />} 
            </TableCell>
            <TableCell>
                {props.icon2 && <CheckCircleRoundedIcon htmlColor="#5658DD" fontSize="small" />}
            </TableCell>
        </TableRow>
    )
}

const TrademarkTableMobile = props => {
    return (
        <TableRow>
            <TableCell>
                <Typography color="textPrimary" variant="body1">{props.text}</Typography>
            </TableCell>
            <TableCell>
                {props.icon && <CheckCircleRoundedIcon htmlColor="#5658DD" fontSize="small" />} 
            </TableCell>
        </TableRow>
    )
}

export default TrademarkGrid
