import React, {useEffect} from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import FAQScreenComponent from '../components/layout/FAQScreenComponent';
import Fade from 'react-reveal/Fade';

const FAQScreen = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <>
      <Header />
      <Fade>
        <FAQScreenComponent />
      </Fade>
      <Footer />
    </>
  );
}

export default FAQScreen