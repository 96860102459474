import React, { useEffect } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import FBAScreenComponent from '../components/layout/FBAScreenComponent';
import Fade from 'react-reveal/Fade';

const FBAScreen = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return (
    <>
        <Header />
          <Fade>
            <FBAScreenComponent />
          </Fade>
        <Footer />
    </>
  );
}

export default FBAScreen;