import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Workflow that just works',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
      id="about_section"
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Interview session
                  </div>
                  <div style={{display: 'flex'}}>
                    <h3 className="mt-0 mb-12">Step 1</h3>
                    <img src={require('../../assets/images/step_icons/step1.png')} style={{marginLeft: 20, width: 40, height: 40 }}/>
                  </div>
                <p className="m-0">
                After conducting a 1:1 personal interview, we’ll find out a winning product for you. A winning product is the one that shows high demand and low competition in the market. As this is key deciding factor in the success of this business, therefore we always carefully select the outstanding products for our valued customers to build a stone hard foundation to your success.
                  </p>
              </div>
              {/* <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                style={{opacity: 0}}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div> */}
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Cost effective Product Sourcing
                  </div>
                  <div style={{display: 'flex'}}>
                    <h3 className="mt-0 mb-12">Step 2</h3>
                    <img src={require('../../assets/images/step_icons/step2.png')} style={{marginLeft: 20, width: 40, height: 40 }}/>
                  </div>
                <p className="m-0">
                After finding a winning product, the product sourcing experts search for a cost effective and premium quality product. At this stage, it is absolutely ensured that the product fulfills our quality control standards.
                  </p>
              </div>
              {/* <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                style={{opacity: 0}}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div> */}
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                 Fast & Reliable Import
                  </div>
                  <div style={{display: 'flex'}}>
                    <h3 className="mt-0 mb-12">Step 3</h3>
                    <img src={require('../../assets/images/step_icons/step3.png')} style={{marginLeft: 20, width: 40, height: 40 }}/>
                  </div>
                <p className="m-0">
                Through our widespread shipment & logistics network, we ensure fast and hassle-free end to end goods import. With our inhouse warehousing and 3PL team, the whole process of goods delivery to Amazon is unmatched.
                  </p>
              </div>
              {/* <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                style={{opacity: 0}}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div> */}
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Product Content and design
                  </div>
                  <div style={{display: 'flex'}}>
                    <h3 className="mt-0 mb-12">Step 4</h3>
                    <img src={require('../../assets/images/step_icons/step4.png')} style={{marginLeft: 20, width: 40, height: 40 }}/>
                  </div>
                <p className="m-0">
                Now it’s all about the creation of a well-designed individual product detail page on Amazon. Creative and eye-catching unique pictures in high resolution give an advance look to your listing. Furthermore, in this step we create highly optimized content well suited to both advertising and ranking purposes.
                  </p>
              </div>
              {/* <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                style={{opacity: 0}}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div> */}
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Product Launching & Ranking
                  </div>
                  <div style={{display: 'flex'}}>
                    <h3 className="mt-0 mb-12">Step 5</h3>
                    <img src={require('../../assets/images/step_icons/step5.png')} style={{marginLeft: 20, width: 40, height: 40 }}/>
                  </div>
                <p className="m-0">
                With multiple years of experience under the belt, our highly qualified experts in coordination with strategy team put together a top-notch product launch and ranking mechanism. We ensure that the newly launched product gets maximum visibility to customers in every possible way so that the target of generating maximum sales velocity is achieved.
                  </p>
              </div>
              {/* <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                style={{opacity: 0}}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div> */}
            </div>


            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Constant maintenance & optimization
                  </div>
                  <div style={{display: 'flex'}}>
                    <h3 className="mt-0 mb-12">Step 6</h3>
                    <img src={require('../../assets/images/step_icons/step6.png')} style={{marginLeft: 20, width: 40, height: 40 }}/>
                  </div>
                <p className="m-0">
                After the product launching phase, it is very important to keep constant optimization checks on the product listing so that it remains on top in the longer term. Furthermore, future inventory planning and many other tasks are efficiently performed to keep the business in healthy shape.
                  </p>
              </div>
              {/* <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                style={{opacity: 0}}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div> */}
            </div>


          </div>
        </div>
      </div>
      <p id="prices_section" style={{opacity: 0}}>scroll</p>
      <br />
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;