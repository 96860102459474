import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [videoModalActive, setVideomodalactive] = useState(false);
  const [sectionItems, setSectionItems] = useState([
    {
      title: 'Your trusted partner in building a successful',
      heading: 'Amazon Business',
      subtitle: 'A successful One-Stop solutions provider for your whole Amazon business journey.',
      image: `url('https://firebasestorage.googleapis.com/v0/b/amz-master.appspot.com/o/Component%205%20%E2%80%93%201.png?alt=media&token=1da62ab8-788d-4fdc-be36-1c4d0329d62f')`,
      btnText: 'Book an appointement',
    },
    {
      title: 'Professional and competent Tax/VAT compliance for your business.',
      heading: 'Accounting & Taxation',
      subtitle: 'Your One-Stop solutions provider for your whole Accounting & Taxation journey.',
      image: "url('https://firebasestorage.googleapis.com/v0/b/amz-master.appspot.com/o/Component%201%20%E2%80%93%201.png?alt=media&token=6bb37dd4-ad4a-461d-9dde-b712f78829f4')",
      btnText: 'Get a quote',
    },
    {
      title: 'Ensure business security through fast and simple ',
      heading: 'Trademark/Brand Registration',
      subtitle: 'Your One-Stop solutions provider for your whole Trademark & Branding journey.',
      image: "url('https://firebasestorage.googleapis.com/v0/b/amz-master.appspot.com/o/Component%202%20%E2%80%93%201.png?alt=media&token=c9ceee41-2438-4f97-8f9d-d5203ce29e2e')",
      btnText: 'Get a quote',
    },
    {
      title: 'Your competent partner for Warehousing and 3PL needs.',
      heading: 'FBA-logistic & warehousing',
      subtitle: 'Your One-Stop solutions provider for your whole Logistics pre-Fba-prep journey.',
      image: "url('https://firebasestorage.googleapis.com/v0/b/amz-master.appspot.com/o/Component%204%20%E2%80%93%201.png?alt=media&token=e7f622d8-e811-46dc-883e-26af4a1349e2')",
      btnText: 'Get a quote'
    }
  ])

  const [selectedSectionItem, setSelectedSectionItems] = useState({
    title: 'Your trusted partner in building a successful',
    heading: 'Amazon Business',
    subtitle: 'Your One-Stop solutions provider for your whole Amazon business journey.',
    image: `url('https://firebasestorage.googleapis.com/v0/b/amz-master.appspot.com/o/Component%205%20%E2%80%93%201.png?alt=media&token=1da62ab8-788d-4fdc-be36-1c4d0329d62f')`,
    btnText: 'Book an appointement'
  })

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const buttonTapped = () => {
    if (selectedSectionItem.btnText === 'Get a quote') {
      window.location.replace('/contact')
   } else {
    window.location.replace('/book')
   }
  }

  useEffect(() => {
    let count = 0

    setInterval(() => {
      setSelectedSectionItems(sectionItems[count])
      count += 1
      if (count === sectionItems.length) { count = 0 }
    }, 6000)

  }, []);

  

  return (
    <section
      {...props}
      className={outerClasses}
      id="home_content"
    >
      <div  style={{backgroundImage: selectedSectionItem.image, marginBottom: '10%',width: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover'}}>
        {/* <Image
                // className="has-shadow"
                style={{zIndex: -1}}
                src={require('./../../assets/images/video-placeholder.jpg')}
                alt="Hero"
                width={896}
                height={504} /> */}
                
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200" style={{fontSize: isMobile ? 30 : 45}}>
              {selectedSectionItem.title} <span style={{color: '#e5c454'}}>{selectedSectionItem.heading}</span>
              {/* Landing template for <span className="text-color-primary">startups</span> */}
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400" style={{color: 'white'}}>
                {/* Our landing page template works on all devices, so you only have to set it up once, and get beautiful results forever.
                 */}
                 {selectedSectionItem.subtitle}
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile onClick={buttonTapped}>
                    {selectedSectionItem.btnText}
                    </Button>
                  <Button tag="a" color="dark" wideMobile href="/contact">
                    Contact Us
                    </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>

          {/* <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/video-placeholder.jpg')}
                alt="Hero"
                width={896}
                height={504} />
            </a>
          </div> */}
          {/* <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe" /> */}
            
            <br /> <br /> <br />
        </div>
      </div>
      <p id="services_section" style={{opacity: 0}}>scroll</p>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;