import React from 'react'
import Grid from '@material-ui/core/Grid';

const amazonMarketIntelligence = [
    {
        title: `Thorough market and competition analysis to `,        
        strong: 'hunt a winning product'
    },
    {
        title: 'Innovative Strategy development & Implementation',
        strong: '',    
    },
    {
        title: '',
        strong: 'Cost effective & Premium Quality product Sourcing'
    },
    {
        title: 'Complete logistics, warehousing & Inventory management',
        strong: ''
    },
    {
        title: 'Branding & Promotions',
        strong: ''
    },
    {
        title: 'Regular optimizations & Reporting',
        strong: ''
    }   
]

const amazonAccountManagement = [
    {
        title: `Amazon - Strategy Consulting`,        
        strong: ''
    },
    {
        title: 'Amazon - Product Listing Creation & Optimization',
        strong: '',    
    },
    {
        title: 'Amazon – Professional Product Photography',
        strong: ''
    },
    {
        title: 'Amazon – SEO Consulting',
        strong: ''
    },
    {
        title: 'Amazon – ',
        strong: 'Brand building Consultancy'
    },
    {
        title: 'Amazon – ',
        strong: 'Product Launching & Ranking'
    },
    {
        title: 'Many More...'
    }
]
    


const ConsultancyServices = () => {

    return (
        <div style={{margin: '1em'}}>
            <h3 style={{textAlign: "center", margin: '2.4em 0px'}}>Our Services</h3>
            <Grid container spacing={3} >
                <>
                    <Grid item xs={12} md={6}>
                        <div>
                            <img style={{ width: '80%', height: '80%', margin: '0 auto'}} src="https://firebasestorage.googleapis.com/v0/b/amz-master.appspot.com/o/services-concept-flat-line-design-icons-elements-modern-services-concept-s-collection-services-concept-lettering-thin-68961333.jpg?alt=media&token=d8e1d869-3653-4b25-aa98-51efdb678ff8" alt="service image" />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h4>Amazon Market Intelligence</h4>
                        <ul>
                            {amazonMarketIntelligence.map((value, index) => (
                                <li>{value.title} <strong>{value.strong}</strong></li>
                            ))}
                        </ul>

                        <button onClick={() => window.location.replace('book')} className="trademark-btn">Hire Our Experts</button>
                    </Grid>
                </>
                <>
                    <div id="amazonAccountManagement"> 
                        <Grid item xs={11} md={11} sm={11}>
                            <h4>Amazon Account Management</h4>
                            <ul>
                                {amazonAccountManagement.map((value, index) => (
                                    <li>{value.title} <strong>{value.strong}</strong></li>
                                ))}
                            </ul>

                            <button onClick={() => window.location.replace('book')} className="trademark-btn">Book an Appointment</button>
                        </Grid>
                    </div>
                </>

            </Grid>
        </div>
    )
}

export default ConsultancyServices
