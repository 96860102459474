import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from './partials/Logo';
// import Dialog from '@material-ui/core/Dialog';
// import Pricing from './Pricing';

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {

  const [isActive, setIsactive] = useState(false);
  // const [openPricingDialog, setOpenPricingDialog] = useState(false);

  const [styleEnable, setStyleEnable] = useState(false)
  const [selectedService, setSelectedService] = useState(-1)

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    // console.log("pricing model", showPricingModel)
    // if(window.location.href.includes('#price') && !openPricingDialog) {
    //   setTimeout(() => {
    //     setOpenPricingDialog(true)
    //   }, 300)
    // }
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  }, []);  

  
  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }  

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );

  const scrollToRedirect = (scroll, replace) => {
    let doc = document.getElementById(scroll)
    if(doc) { doc.scrollIntoView({behavior: 'smooth', block: 'start'}) } 
    else { window.location.replace(replace) }
  }

  // const showPricingModel = () => {
  //   const split = window.location.href.split('/').filter(el => el.length > 0)
  //   if (split.length === 2) {
  //     setOpenPricingDialog(true)
  //   } else {
  //     window.location.replace('/#price')
  //   }
  // }

  const enableHandler = service => {
    setStyleEnable(true)
    setSelectedService(service)
  }

  const disableHandler = () => {
    setStyleEnable(false)
    setSelectedService(-1)
  }

  return (
    <header
      {...props}
      className={classes}
      // style={{position: 'fixed', top: 0}}
    >
      {/* <Dialog
        fullWidth={true} 
        maxWidth='xl'
        open={openPricingDialog}
        onClose={() => setOpenPricingDialog(false)}>
        <Pricing />
      </Dialog> */}

      <div className="container">
        <div className={
          classNames(
            'site-header-inner',
            bottomDivider && 'has-bottom-divider'
          )}>
          <Logo />
          {!hideNav &&
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={
                  classNames(
                    'header-nav',
                    isActive && 'is-active'
                  )}>
                <div className="header-nav-inner">
                  <ul className={
                    classNames(
                      'list-reset text-xs',
                      navPosition && `header-nav-${navPosition}`
                    )}>
                    <li>
                      <Link onClick={() => scrollToRedirect("home_content", '/')}>Home</Link>
                    </li>
                    <li className="dropdown">
                      <Link onClick={() => scrollToRedirect("services_section", "/")} >Services</Link>
                      <div className="dropdown-content" style={{backgroundColor: '#151719'}}>                        
                        <a style={selectedService === 1 && styleEnable ? myStyle : {}} onMouseOver={() => enableHandler(1)} onMouseLeave={disableHandler} className="serviceTabs" href="/fba">Amazon FBA Consultancy</a>
                        <a style={selectedService === 2 && styleEnable ? myStyle : {}} onMouseOver={() => enableHandler(2)} onMouseLeave={disableHandler} href="/accounting">Accounting & Taxation</a>
                        <a style={selectedService === 3 && styleEnable ? myStyle : {}} onMouseOver={() => enableHandler(3)} onMouseLeave={disableHandler} href="/trade">Trademark & Branding</a>
                        <a style={selectedService === 4 && styleEnable ? myStyle : {}} onMouseOver={() => enableHandler(4)} onMouseLeave={disableHandler} href="/logistics">FBA-logistic & warehousing</a>
                      </div>
                    </li>
                    {/* <li>
                      <Link onClick={() => document.getElementById("about_section").scrollIntoView({behavior: 'smooth'})}>About Us</Link>
                    </li> */}
                    <li>
                      {/* <Link onClick={showPricingModel}>Prices</Link> */}
                      <Link to='/prices'>Prices</Link>
                    </li>
                  <li>
                     {/* onClick={() => document.getElementById("team_section").scrollIntoView({behavior: 'smooth'})} */}
                      <Link to="/about-us">About us</Link>
                    </li>
                    <li>
                    {/* onClick={() => document.getElementById("contact_section").scrollIntoView({behavior: 'smooth'})} */}
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/faq" onClick={closeMenu}>FAQs</Link>
                    </li>
                    <li className="dropdown">
                      <Link to="#0" onClick={closeMenu}>
                        <img src={require('../../assets/images/drop_down_arrow.png')} style={{width: 24, height: 24}} />
                      </Link>
                      <div class="dropdown-content">
                        
                        <a href="#">
                          <img src={require('../../assets/images/uk.png')} style={{width: 34, height: 20, marginRight: 10}} />
                          {/* <p> English</p> */}
                        </a>
                        {/* style={{display: 'flex', flexDirection: 'row', alignContent: 'flex-start'}} */}
                        <a href="#">
                          <img src={require('../../assets/images/german.png')} style={{width: 34, height: 20, marginRight: 10}} />
                          {/* <p>German</p> */}
                        </a>

                        </div>
                    </li>
                  </ul>
                  {/* {!hideSignin &&
                    <ul
                      className="list-reset header-nav-right"
                    >
                      <li>
                        <Link to="#0" className="button button-primary button-wide-mobile button-sm" onClick={closeMenu}>Sign up</Link>
                      </li>
                    </ul>} */}
                </div>
              </nav>
            </>}
        </div>
      </div>
    </header>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;

const myStyle = {
  backgroundColor: '#6163FF',
  color: 'white'
}