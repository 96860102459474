import React from 'react'
import ConsultancySales from './ConsultancySales'
import ConsultancyServices from './ConsultancyServices'
import ConsultancyWinning from './ConsultancyWinning'

class FBAScreenComponent extends React.Component {
    render() {
        return(
            <section style={{ marginTop: '100px' }}>
                {/* Sales */}
                <ConsultancySales />
                {/* Services */}
                <ConsultancyServices /> 
                {/* Winning */}
                <ConsultancyWinning />
            </section>
        )
    }
}

export default FBAScreenComponent