import React, { useEffect } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import TeamScreenComponent from '../components/layout/TeamScreenComponent';
import Fade from 'react-reveal/Fade';

const TeamScreen = () => {
  
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <>
        <Header />
          <Fade>
            <TeamScreenComponent />
          </Fade>
        <Footer />
    </>
  );
}

export default TeamScreen;