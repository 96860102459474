import React, { useEffect } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Pricing from '../components/layout/Pricing';
import Fade from 'react-reveal/Fade';
import PriceList from '../components/layout/PriceList';

const PricingScreen = () => {
  
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <>
        <Header />
          <Fade>
            <section style={{marginTop: 100}}>
              <PriceList />
            </section>
            {/* <Pricing /> */}
          </Fade>
        <Footer />
    </>
  );
}

export default PricingScreen;