import React, { useEffect } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import AccountingScreenComponent from '../components/layout/AccountingScreenComponent';
import Fade from 'react-reveal/Fade';
import AccountingTaxationScreen from '../components/layout/AccountingTaxationScreen';

const AccountingScreen = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return (
    <>
      <Header />
      <Fade> 
      <section style={{ marginTop: 100 }}>
        <AccountingTaxationScreen />
          </section>
            {/* <AccountingScreenComponent /> */}
        </Fade>
      <Footer />
    </>
  );
}

export default AccountingScreen;