import React from 'react'

const ConsultancySales = () => {
    return (
        <div>
                            <div>
                    <h2 className="image-header">Build & Accelerate Your Brand Sales on AMAZON With <span style={{color: '#E5C454'}}>Highly Professional A to Z Consulting Services from AMZ-MASTER</span></h2>
                    <div className="image-contact-div">
                        <img className="image-contacts" src={require("../../assets/images/fba.jpeg")} alt="" />
                    </div> 
                </div>
                <br />
                <br />
                <br />
            <p id="consuntancySalesPara">AMZ-MASTER is an agency committed to provide top notch data-driven support, product, sales and private label brand consultancy, and A to Z account management to Amazon Sellers across the globe.</p>
            <div style={{textAlign: 'center', margin: '0 auto', width: '90%'}}>
                <button onClick={() => window.location.replace('book')} className="trademark-btn">Book a free appointment & talk to our experts</button>
            </div>
        </div>
    )
}

export default ConsultancySales
