import React from 'react'

export default class AccountingScreenComponent extends React.Component {
    render() {
        return(
            <section style={{marginTop: 100}}>
                <center><h3>Page development in progress will be updated soon</h3></center>
            </section>
        )
    }
}