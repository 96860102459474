import React from 'react'
import TradeFeatureTiles from '../sections/TradeFeatureTiles'
import TrademarkGrid from './partials/TrademarkGrid';

export default function TradeScreenComponent() {
    return (
        <section style={{marginTop: 40}}>
            <div style={{marginBottom: 40}}>
                <h3 className="image-header">Register a trademark</h3>
                <h3 className="image-header" style={{marginTop: "2.7em"}}>Find a trademark quickly and easily</h3>
                <div className="image-contact-div">
                    <img className="image-contacts" src={require("../../assets/images/trademark.jpg")} alt="" />
                </div> 
            </div>
            <TrademarkGrid />
            {/* <TradeFeatureTiles /> */}
            <img style={{width: "350px", margin: "0 auto"}} src={require("../../assets/images/pay2.png")} alt="" />
        </section>
    )
}
