import React, {useEffect} from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Fade from 'react-reveal/Fade';
import BookScreenComponent from '../components/layout/BookScreenComponent';

const BookScreen = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <>
      <Header />
      <BookScreenComponent />
      <Footer />
    </>
  );
}

export default BookScreen;