import React from 'react'
import Image from '../elements/Image'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#6F1FFA',
        color: theme.palette.common.white,
  },
  body: {
      fontSize: 14,
      color: "#fff"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#25282C',
        },
      '&:nth-of-type(even)': {
            backgroundColor: '#25282C',
        },
  },
}))(TableRow);

function createData(name, price) {
  return { name, price };
}

const rows = [
    createData('German VAT Registration (Tax Number, VAT ID, F22 Certificate):', 'Fix Price € 250'),
    createData('German VAT Declaration:', 'Fix Price € 70 per Month'),
];

const useStyles = makeStyles({
  table: {
    minWidth: 400,
  },
});

const AccountingTaxationScreen = () => {

    const classes = useStyles();

    return (
        <div> 
            <div>
                <h2 className="image-header">Accounting & Taxation</h2>
                <div className="image-contact-div">
                    <img className="image-contacts" src={require("../../assets/images/tax.jpg")} alt="" />
                </div> 
            </div>

            <div style={{ textAlign: "center" }}>
                <h3>Hassle free Sales tax &amp; Income Tax compliance for online retailers</h3>
                <p className="content-text content-text-spacing">Get yourself out of the tax jungle by putting your sales tax compliance on autopilot. We take care of your VAT registrations and VAT declarations. You focus only on growing your business and we make sure that you remain fully tax compliance.</p>
                <p className="content-text content-text-spacing">Which countries do we cover?</p>
                
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Image
                        src={require('./../../assets/images/german.png')}
                        alt="German flag"
                        width={"20%"}
                        height={"20%"}
                        style={{margin: "10px", marginLeft: '60px', marginRight: '60px'}}
                    />

                    <Image
                        src={require('./../../assets/images/uk.png')}
                        alt="UK flag"
                        width={"20%"}
                        height={"20%"}
                        style={{margin: "10px", marginLeft: '60px', marginRight: '60px'}}
                    />
                </div>
            </div>
            
            <div>
                {/* IMAGE - Flow Chart */}
            </div>

            <div style={{ display: "block", margin: "10px 15%" }}>
                
                <div style={{ padding: '2em 0em', paddingBottom: '1em'}}>
                    <TableContainer style={{ borderRadius: "6px", maxWidth: '800px', margin: '0px auto'}}>
                        <Table  className={classes.table} aria-label="customized table">
                            <TableHead style={{margin: "20px"}}>  
                                <TableRow>
                                    <StyledTableCell>
                                        <p style={{margin: "0px 20px"}}>Description</p>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <p style={{margin: "0px 20px"}}>Price</p>
                                    </StyledTableCell>
                                </TableRow>
                                </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell component="th" scope="row">                                    
                                        <p style={{margin: "0px 20px"}}>{row.name}</p>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <p style={{margin: "0px 20px"}}>{row.price}</p>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                                
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                
                
                {/* <>
                    <h5 style={{color: "red"}}>German VAT Registration (Tax Number, VAT ID, F22 Certificate):<span style={{color: "#00B050"}}> Fix Price 250 €</span> </h5>
                    <h5>German VAT Declaration:<span style={{ color: "#00B050" }}> Fix Price € 70 per Month</span> </h5>
                </> */}
                
                <>
                    <h5 style={{color: "#6163FF", textAlign: "center", fontSize: '26px', padding: '2em 0em'}}>Absolutely free initial consultancy!</h5>
                    <p>To get you on board, one of our expert accountants will provide you absolutely free initial consultancy.</p>
                </>
                
                
                <div style={{padding: '1em 0em'}}>
                    <h5 style={{textAlign: 'center', color: '#6163FF'}}>Documents needed for VAT registration:</h5>
                    <ul>
                        <li>Company incorporation certificate</li>
                        <li>Company’s Tax number and VAT number from the country where it is established</li>
                        <li>Company director’s copy of the ID Document (Preferably a copy of valid Passport)</li>
                        <li>Power of attorney to act on company’s behalf (We&#39;ll generate and provide the power of attorney letters in German language; you’ll have to just sign and send back to us)</li>
                        <li>A Pdf export from Amazon Seller Central showing the seller name. Follow the steps below to get that. Open Amazon de marketplace then Go to Settings —&gt; Account Info —&gt; Your seller profile. Now give print command and save the whole page as a pdf document</li>
                        <li>Categories in which the company intends to sell the products</li>
                        <li>A Performa or Commercial Invoice indicating the company’s goods buying activity</li>
                    </ul>
                </div>

                <div>
                    <h5>How long is the registration process?</h5>
                    <p>The typical processing time is about 4 to 6 weeks.</p>
                </div>
            </div>

            <div style={{textAlign: "center"}}>
                <button className="teams-contact-btn" onClick={() => window.location.replace('contact')}>Contact Us</button>
            </div>

        </div>
    )
}

export default AccountingTaxationScreen