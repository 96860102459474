import React from 'react'

const ConsultancyWinning = () => {
    return (
        <div>
            <h3 style={{ textAlign: "center", margin: '2.4em 0px', padding: '0 2em' }}>Increase Your Chances of Winning the Buy Box</h3>
            <WinningBox header={'Product Management'} text={"A successful winning strategy on Amazon requires precise and accurate Product Management right from the start with innovative, out-of-the-box thinking, planning, and implementation to ensure high rewarding return on investment (ROI)."} />
            <WinningBox header={'Amazon Marketing Consultancy'} text={"High qualified and experienced AMZ- MASTER team will help to create and optimize a great Product detail page, Advertising spend, and other areas to boost sales and ROI. We'll guide and help you through the variety of options and find the optimal package suiting your business needs."} />
            <div style={{textAlign: 'center', margin: '0 auto', width: '90%'}}>
                <button onClick={() => window.location.replace('book')} className="trademark-btn">Talk to Expert</button>
            </div>
        </div>
    )
}

const WinningBox = props => {
    return (
        <div style={{margin: '2em auto', textAlign: 'center'}}>
            <h4 style={{color: '#E5C454', width: '80%'}}>{props.header}</h4>
            <p id="winningBoxPara">{props.text}</p>
        </div>
    )
}

export default ConsultancyWinning
